import localStorageHelper from "helpers/localStorage"
import formatterHelper from "helpers/formatter"
import stateHelper from "helpers/state"
import cookieHelper from "helpers/cookies"
import urlHelper from "helpers/url"

import selectAuthentication from "selectors/authentication"
import TrackingSegment from "managers/segmentManager"

import { set as setSignup } from "actions/signup"

import i18n from "consts/i18n"

import { get } from "lodash"

// consts
import {
  CHOOSE_CURRENT_PROFILE,
  SET_SESSION,
  LOGIN_PENDING,
  LOGIN_FAILURE,
  LOGOUT,
  CHECK_REQUEST_QUEUE,
  ACCESSTOKEN_REFRESH_PENDING,
  AUTOLOGIN,
  AUTOLOGIN_ERROR,
  CLEAN_SUBSCRIPTIONS,
  CHECK_LOGIN,
  PENDING_CLIENT_SESSION,
  CLEAR_LOGIN_ERROR,
  OAUTH_AUTHORIZE_PENDING,
  OAUTH_AUTHORIZE_SUCCESS,
  OAUTH_AUTHORIZE_FAILURE,
  OAUTH_AUTHORIZE_REQUESTED,
  SOCIAL_LOGIN_ERROR,
  SET_HAS_PASSWORD,
} from "consts/actions"
import consts from "consts"

export function setHasPassword(hasPassword) {
  return {
    type: SET_HAS_PASSWORD,
    payload: { hasPassword: hasPassword },
  }
}

export function setSession(session) {
  TrackingSegment.identify(get(session, "account.id"))
  session.refreshPending && delete session.refreshPending
  localStorageHelper.setSession(session)
  return {
    type: SET_SESSION,
    payload: { data: session },
  }
}

export function chooseCurrentProfile() {
  return {
    type: CHOOSE_CURRENT_PROFILE,
  }
}

export function newAccessToken(data) {
  return (dispatch, getState) => {
    const session = getState().session.data

    if (session) {
      const updatedSession = { ...session, auth: { ...session.auth, ...data } }
      dispatch(setSession(updatedSession, false))
    }
  }
}

export function invalidAccessToken() {
  return (dispatch, getState) => {
    const session = getState().session.data

    if (session && (!session.error || session.auth.access_token)) {
      let updatedSession = { ...session }
      updatedSession.error = true
      delete updatedSession.auth.access_token
      dispatch(setSession(updatedSession))
    }
  }
}

export function tokenLogin(token) {
  return login({
    token: token,
    grant_type: "token",
  })
}

export function facebookLogin(token, contextData) {
  return login(
    {
      token: token,
      grant_type: "facebook",
    },
    contextData
  )
}

export function googleLogin(token, ssoAppId, contextData, params = {}, prevRoute) {
  return login(
    {
      token: token,
      grant_type: "google",
      sso_app_id: ssoAppId,
      ...params,
    },
    contextData,
    prevRoute
  )
}

export function appleLogin(data, contextData, params) {
  return login(
    {
      token: data.authorization.id_token,
      grant_type: "apple",
      email: get(data, "user.email"),
      first_name: get(data, "user.name.firstName"),
      last_name: get(data, "user.name.lastName"),
      ...params,
    },
    contextData
  )
}

export function passwordLogin(email, password, contextData) {
  return login(
    {
      email: email,
      password: password,
      grant_type: "password",
    },
    contextData
  )
}

export function clearLoginError() {
  return {
    type: CLEAR_LOGIN_ERROR,
  }
}

export function allowOAuth(params) {
  return (dispatch, getState) => {
    const state = getState()
    const authentication = selectAuthentication(state)

    params.access_token = authentication.accessToken
    params.response_type = params.response_type || "code"
    params.allowed = params.allowed || false

    dispatch({
      request: {
        url: consts.endpoints.oauthAuthorize,
        method: "POST",
        body: params,
      },
      accessTokenRequired: true,
      onStart: () => {
        return {
          type: OAUTH_AUTHORIZE_PENDING,
        }
      },
      onSuccess: payload => {
        return {
          type: payload.error ? OAUTH_AUTHORIZE_FAILURE : OAUTH_AUTHORIZE_SUCCESS,
          payload,
        }
      },
      onError: payload => {
        return {
          type: OAUTH_AUTHORIZE_FAILURE,
          payload: stateHelper.errorPayload(payload.error.user_message || i18n.OAuthPage.errorDefault, payload.error.error_code),
        }
      },
    })
  }
}

export function fetchOAuth(params) {
  return (dispatch, getState) => {
    const state = getState()
    const authentication = selectAuthentication(state)

    const requestParams = {
      client_id: params.client_id,
      redirect_uri: params.redirect_uri,
      response_type: params.response_type || "code",
      access_token: authentication.accessToken,
      state: params.state,
    }

    if (params.scope) {
      requestParams.scope = params.scope
    }

    dispatch({
      request: {
        url: consts.endpoints.oauthAuthorize,
        method: "GET",
        params: requestParams,
      },
      accessTokenRequired: true,
      onStart: () => {
        return {
          type: OAUTH_AUTHORIZE_PENDING,
        }
      },
      onSuccess: payload => {
        if (payload.error) {
          return {
            type: OAUTH_AUTHORIZE_FAILURE,
            payload: stateHelper.errorPayload(payload.error_description || i18n.OAuthPage.errorDefault, payload.error),
          }
        }

        if (payload.application_name) {
          return {
            type: OAUTH_AUTHORIZE_REQUESTED,
            payload,
          }
        }

        return {
          type: OAUTH_AUTHORIZE_SUCCESS,
          payload,
        }
      },
      onError: payload => {
        return {
          type: OAUTH_AUTHORIZE_FAILURE,
          payload: stateHelper.errorPayload(payload.error.user_message || i18n.OAuthPage.errorDefault, payload.error.error_code),
        }
      },
    })
  }
}

export function getContextAuthHeaders(contextData) {
  let headers = {}

  if (contextData) {
    if (contextData.originPage) {
      headers[consts.headers.xActionContext] = JSON.stringify({
        origin_page: contextData.originPage,
      })
    }
    if (contextData.deviceTargetAgent) {
      headers[consts.headers.parentAgent] = JSON.stringify(contextData.deviceTargetAgent)
    }
  }

  return headers
}

function login(requestBody, contextData, prevRoute) {
  return (dispatch, getState) => {
    const url = urlHelper.getReferrerAndAllInfoInUrlForLoginOrSignup(consts.endpoints.login, getState)
    return dispatch({
      accessTokenRequired: false,
      request: {
        url,
        method: "POST",
        headers: getContextAuthHeaders(contextData),
        body: requestBody,
      },
      onStart: () => {
        return {
          type: LOGIN_PENDING,
        }
      },
      onSuccess: payload => {
        if (requestBody.grant_type === "password") {
          localStorageHelper.setCachedData(consts.localStorageKey.lastEmailLogged, requestBody.email)
        }
        cookieHelper.deleteCookie({ name: consts.cookieNames.clickId, domain: urlHelper.getCurrentDomainWithExtension() })

        //@dev track user login via google API
        window.dataLayer &&
          prevRoute &&
          window.dataLayer.push({
            event: "gtm.custom_event",
            event_name: "sign_up",
            event_category: prevRoute.includes("offer") ? "sign_up_page_offer" : "sign_up_form",
            event_action: "sign_up_success",
            event_label: "google",
            previous_page_path: `${prevRoute}`,
            funnel: "seo_program_site",
          })

        //@dev track user login within the 'watch now' cta funnel
        const product = JSON.parse(sessionStorage.getItem("watchNowCtaTarget"))

        window.dataLayer &&
          product &&
          window.dataLayer.push({
            event: "gtm.custom_event",
            event_name: "log_in",
            event_category: "log_in_watch_now_cta",
            event_action: "log_in_success",
            event_target: product,
            funnel: "watch_now",
          })

        return setSession(payload)
      },
      onError: (payload, meta, dispatch) => {
        if (get(payload, "error.error_code") === consts.api.errorCodes.SIGNUP_MISSING_FIELDS) {
          dispatch({
            type: SOCIAL_LOGIN_ERROR,
            payload: {
              token: requestBody.token,
              ssoAppId: requestBody.ssoAppId,
              grantType: requestBody.grant_type,
              contextData,
            },
          })
          dispatch(setSignup("signupInformations"))
          return
        }

        return {
          type: LOGIN_FAILURE,
          payload: stateHelper.errorPayload(payload.error.user_message, payload.error.error_code),
        }
      },
    })
  }
}

export function logout() {
  TrackingSegment.logout()
  localStorageHelper.clear()
  cookieHelper.deleteCookie({ name: consts.cookieNames.userTracking, domain: urlHelper.getCurrentDomainWithExtension() })

  return dispatch => {
    dispatch({
      type: LOGOUT,
    })

    // Reset store subscriptions
    dispatch({
      type: CLEAN_SUBSCRIPTIONS,
    })
  }
}

export function refresh() {
  return (dispatch, getState) => {
    const state = getState()
    const authentication = selectAuthentication(state)

    if (authentication.isRefreshing === true) {
      return
    }

    dispatch({
      request: {
        url: formatterHelper.basic(consts.endpoints.refreshToken, { refreshToken: authentication.refreshToken }),
      },
      accessTokenRequired: false,
      onStart: () => {
        return {
          type: ACCESSTOKEN_REFRESH_PENDING,
        }
      },
      onSuccess: payload => {
        dispatch(newAccessToken(payload))
        dispatch({
          type: CHECK_REQUEST_QUEUE,
        })
      },
      onError: () => {
        logout()
      },
    })
  }
}

export function checkAutologinViaAccessToken() {
  // Get url - Get accessToken if it exists - Set a clean URL
  const currentLocation = window.location
  const params = currentLocation.search ? currentLocation.search.substr(1).split("&") : []
  let accessToken = null
  const finalParams = params.filter(param => {
    const tmp = param.split("=")
    if (tmp[0] === "token") {
      accessToken = tmp[1]
    } else return true
  })

  const cleanedURL =
    currentLocation.origin + currentLocation.pathname + (finalParams.length ? "?" + finalParams.join("&") : "") + currentLocation.hash
  window.history.replaceState({}, null, cleanedURL)

  if (accessToken) {
    return tokenLogin(accessToken)
  }
}

export function checkAutologinViaRefreshToken() {
  return dispatch => {
    // Get url - Get refreshToken if it exists - Set a clean URL
    const currentLocation = window.location
    const params = currentLocation.search ? currentLocation.search.substr(1).split("&") : []
    let refreshToken = null
    const finalParams = params.filter(param => {
      const tmp = param.split("=")
      if (tmp[0] === "refreshToken") {
        refreshToken = tmp[1]
      } else return true
    })

    const cleanedURL =
      currentLocation.origin + currentLocation.pathname + (finalParams.length ? "?" + finalParams.join("&") : "") + currentLocation.hash
    window.history.replaceState({}, null, cleanedURL)

    // If we have a refreshToken we try to autolog
    function getAccessTokenFromRefresh(refreshToken) {
      dispatch({
        queueing: false,
        request: {
          url: formatterHelper.basic(consts.endpoints.refreshToken, { refreshToken }),
        },
        onStart() {
          return { type: AUTOLOGIN }
        },
        onSuccess(payload) {
          getSessionFromAccess(payload.access_token, refreshToken)
        },
        onError() {
          return { type: AUTOLOGIN_ERROR }
        },
      })
    }

    function getSessionFromAccess(accessToken, refreshToken) {
      dispatch({
        forceToken: accessToken,
        queueing: false,
        request: {
          url: consts.endpoints.me,
          method: "GET",
        },
        onSuccess: payload => {
          payload.auth = payload.auth || {}
          payload.auth.access_token = accessToken
          payload.auth.refresh_token = refreshToken
          return setSession(payload)
        },
        onError() {
          return { type: AUTOLOGIN_ERROR }
        },
      })
    }

    if (refreshToken) {
      getAccessTokenFromRefresh(refreshToken)
    }
  }
}

export function checkLogin() {
  return {
    type: CHECK_LOGIN,
    payload: localStorageHelper.getSession(),
  }
}

export function pendingClientSession() {
  return {
    type: PENDING_CLIENT_SESSION,
  }
}
