import React from "react"

import { Link } from "react-router-dom"

import styles from "./index.css"
import consts from "consts"

export default {
  default: {
    breadcrumb: "Programme TV",
    h1: "Programme TV : que regarder ce soir ?",
    description: (
      <div>
        <h2 className={styles.bottomTextTitle}>Découvrez le Programme TV Complet sur Molotov</h2>
        <div className={styles.bottomTextSection}>
          <p>
            Vous cherchez à ne rien rater de votre{" "}
            <a href={consts.externalLink.emissionsTv} aria-label="emissions tv link">
              émission préférée
            </a>{" "}
            ? Avec Molotov, accédez à un programme TV gratuit et complet, qui vous permet de visualiser tous les programmes disponibles sur les
            chaînes de TNT françaises. Que vous soyez fan de séries, de films ou d’événements sportifs, Molotov vous offre une expérience de
            visionnage innovante et intuitive.
          </p>
        </div>

        <div className={styles.bottomTextSection}>
          <h3 className={styles.subTitle}>
            <strong>Consultez le Programme Télé Ce Soir</strong>
          </h3>
          <p>
            Ne passez plus des heures à chercher ce que vous allez regarder ! Grâce à son interface intuitive, Molotov vous permet de retrouver
            facilement le programme télé ce soir en un clin d'œil. Que vous souhaitiez suivre les dernières aventures de votre série favorite ou ne
            pas manquer le dernier match de football sur TF1 ou M6, Molotov vous propose un aperçu détaillé de l’ensemble des chaînes de télévision
            françaises.
          </p>
        </div>

        <div className={styles.bottomTextSection}>
          <h3 className={styles.subTitle}>
            <strong>Le Programme TNT à Portée de Main</strong>
          </h3>
          <p>
            Avec Molotov, accédez à l’intégralité du programme TNT directement depuis votre smartphone, tablette ou ordinateur. Molotov vous permet de
            suivre le programme télé en temps réel, de voir les rediffusions, les <Link to={consts.routes.tvReplay}>replay</Link> et même de profiter
            des contenus à la demande. Oubliez les soucis de planning : regardez ce que vous voulez, quand vous le voulez !
          </p>
        </div>

        <div className={styles.bottomTextSection}>
          <h3 className={styles.subTitle}>
            <strong>Profitez d'un Accès Facile aux Chaînes Françaises</strong>
          </h3>
          <p>
            Profitez d’un accès direct à toutes les chaînes de télévision françaises, qu'il s'agisse de grandes chaînes comme TF1, France Télévisions,
            M6, W9, Arte, ou des chaînes locales ou thématiques. Grâce à notre guide des programmes, vous ne raterez jamais un épisode ou un événement
            important. Avec Molotov, naviguez facilement entre les différentes options de <strong>programme télé</strong>. <br /> <br /> Suivez toute
            l’actu TV, organisez votre expérience de visionnage à votre façon, et retrouvez ce qui passe ce soir, en ce moment, ou demain à la
            télévision.
          </p>
        </div>
      </div>
    ),
  },
  live: {
    menuLabel: "TV en direct",
    meta: {
      title: "Toute la TV en streaming direct sur Molotov.tv",
      description:
        "Regardez toute la télévision en streaming direct sur Molotov : toutes les chaînes de la TNT sur tous vos écrans : smartphone, ordinateur, tablette, télévision.",
    },
    h1: "Programme TV : toutes les chaînes télé en direct",
    h2: "En ce moment à la télévision",
    breadcrumb: "Télévision en direct",
  },
  tonight_1: {
    menuLabel: "TV ce soir",
    meta: {
      title: "Programme TV de ce soir sur toutes les chaînes TNT - Molotov.tv",
      description: "Retrouvez les programmes télé de ce soir et regardez la TNT en direct en téléchargeant l'application Molotov.",
    },
    h1: "Programme TV ce soir sur Molotov",
    h2: "Ce soir à la télé",
    breadcrumb: "Ce soir à la télé",
    description: (
      <div>
        <h2 className={styles.bottomTextTitle}>Votre programme TV complet de ce soir sur Molotov</h2>
        <div className={styles.bottomTextSection}>
          <p>Découvrez ce qui passe ce soir à la télévision avec Molotov.</p>
          <p>
            Le programme télé de votre soirée est disponible pour l'ensemble des chaînes disponibles sur nos applications dont vos chaînes TNT TF1,
            France 2, M6, W9, C8 ou encore BFM TV. Une fois votre émission choisie, installez-vous confortablement et regardez{" "}
            <Link to={consts.routes.tvStreaming}>la tv en streaming</Link>.
          </p>
          <p>En faisant visuellement le choix de ce qui sera diffusé ce soir à la télé, planifiez-vous une soirée tv parfaite avec Molotov !</p>
        </div>

        <div className={styles.bottomTextSection}>
          <p>
            Sur ce programme TV, visualisez facilement la grille de vos emissions en première partie (à partir de 20h30) et en seconde partie de
            soirée tv (programmes commençant dès 22h) pour planifier votre fin de journée télévisuelle.
          </p>
          <p>
            Molotov propose au sein de ses offres payantes d'enregistrer vos programmes et émissions. Il est ainsi facile de découvrir les films à la
            télé ou les séries TV qui vont commencer pour les enregistrer en un clic et les regarder ensuite à votre rythme. L'application étant
            disponible sur de nombreux appareils, vous pouvez enregistrer le programme de votre choix sur notre{" "}
            <Link to={consts.routes.deviceIphone}>app pour iPhone</Link> ou <Link to={consts.routes.deviceAndroid}>l'appli tv pour Android</Link> et
            regarder ensuite ce programme sur un plus grand écran - que ce soit sur{" "}
            <Link to={consts.routes.tvPublic}>nos app pour télévisions connectées</Link> ou notre{" "}
            <Link to={consts.routes.deviceDesktop}>application tv pour ordinateur</Link>.
          </p>
        </div>

        <div className={styles.bottomTextSection}>
          <p>
            Le programme TV de ce soir est complet. Vous trouverez ainsi vos chaînes TNT (TF1, les chaînes de France Télévisions, Arte, M6, C8, W9,
            ...) mais aussi les chaînes disponibles dans nos <Link to={consts.routes.products}>options payantes</Link>. Retrouvez-ainsi ci-dessus les
            programmes pour l'option OCS et ses 4 chaînes 100% cinéma séries (OCS Max, OCS Choc, OCS City, OCS Géant), pour l'option Ciné+ dédiée au
            cinéma avec de grands films chaque soir ou encore ceux de l'option thématique Extended qui ravira toute la famille. A retrouver notamment
            les chaînes de qualité telles que Paris Première, Teva, AB1 sur du divertissement, Disney Channel, Canal J ou encore Toonami pour ravir
            les plus jeunes - ainsi que d'autres chaînes axées Musique, Documentaire ou Films.
          </p>
        </div>
      </div>
    ),
  },
  tonight_2: {
    menuLabel: "TV fin de soirée",
    meta: {
      title: "Programme TV 2ème partie de soirée",
      description: "Retrouvez tous les programmes en deuxième partie de soirée sur Molotov, à venir et en direct sur Molotov.tv",
    },
    h1: "Programme TV : ce soir à la télé sur Molotov",
    h2: "Programmes télé en 2ème partie de soirée",
    breadcrumb: "Programme TV fin de soirée",
  },
  emissions_tv: {
    menuLabel: "Emissions TV",
  },
  future: {
    menuLabel: "Programmes TV populaires",
    meta: {
      title: "Tous les programmes télé populaires à venir sur Molotov",
      description: "Retrouvez tous les programmes populaires à venir sur Molotov.",
    },
    h1: "Programme TV : les plus regardés sur Molotov",
    h2: "Programmes populaires à venir",
    breadcrumb: "Programmes TV populaires",
  },
}
