import { createSelector } from "reselect"

const selectSession = state => state.session

const selectAuthentication = createSelector([selectSession], session => {
  return {
    accessToken: session.data && session.data.auth.access_token,
    refreshToken: session.data && session.data.auth.refresh_token,
    isAuthenticated: session.data && session.data.auth.refresh_token ? true : false,
    needRefresh: session.data && session.data.auth.refresh_token && !session.data.auth.access_token,
    isRefreshing: session.refreshPending || false,
    isAutologin: session.autologin || false,
    choseProfile: session.choseProfile || false,
    isPending: session.pending || false,
    hasAccessToken: session.data && session.data.auth.access_token ? true : false,
    error: session.error || false,
  }
})

export default selectAuthentication
