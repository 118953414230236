import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import classNames from "classnames"
import { get } from "lodash"

import Icon, { IconTypes } from "components/Icon"
import Logo from "./assets/logo"
import YellowLogo from "./assets/yellowLogo"
import modalTypes from "containers/Modal/types"

import { open as openModal } from "actions/modal"

import { getAssetURL } from "helpers/static"
import routeHelpers from "helpers/route"
import formatterHelper from "helpers/formatter"

import { selectReferencesModel } from "selectors/references"
import { selectLocale } from "selectors/local"

import styles from "./index.css"

import consts from "consts"
import i18n from "consts/i18n.js"
import selectNavigator from "selectors/navigator"

export default function Footer({ isDarkTheme, width = 1260 }) {
  const dispatch = useDispatch()
  const references = useSelector(selectReferencesModel)
  const locale = useSelector(selectLocale)
  const pathname = useSelector(state => state.router.location.pathname)
  const navigator = useSelector(selectNavigator)
  const onClickDownload = () => {
    // ugly workaround here, by default we should use the adjust link with fallback query for a desktop, but for some reason fallback isn't working from adjust side
    if (navigator.isMac || navigator.isWindows || navigator.isWindows64 || navigator.isLinux) {
      window.location = "/download?campaign=marketing_site"
    } else {
      window.location = routeHelpers.getDownloadURL("marketing_site")
    }
  }

  const openCookieModal = e => {
    e?.preventDefault()
    dispatch(openModal(modalTypes.cookie, null, null, false))
  }

  const channels = references
    .getTvBundles()
    .reduce((acc, bundle) => {
      return acc.concat(bundle.channels)
    }, [])
    .filter(channel => !channel.isVirtual())

  const categories = references.categories
  const showSocialIcons = pathname === "/"

  return (
    <footer
      className={classNames(styles.root, {
        [styles.rootDark]: isDarkTheme,
      })}
    >
      <div className={styles.wrapper} style={{ maxWidth: width }}>
        <div className={styles.header}>
          <div className={styles.logo}>{isDarkTheme ? <YellowLogo /> : <Logo />}</div>
          {showSocialIcons && (
            <div className={styles.icons}>
              <a href={consts.externalLink.facebook} target="_blank" aria-label="facebook">
                <Icon
                  type={IconTypes.facebookFull}
                  rootStyle={styles.social}
                  iconStyle={classNames(styles.icon, styles.iconFacebook, {
                    [styles.iconDark]: isDarkTheme,
                  })}
                />
              </a>
              <a href={consts.externalLink.instagram} target="_blank" aria-label="instagram">
                <Icon
                  type={IconTypes.instagram}
                  rootStyle={styles.social}
                  iconStyle={classNames(styles.icon, {
                    [styles.iconDark]: isDarkTheme,
                  })}
                />
              </a>
              <a href={consts.externalLink.twitter} target="_blank" aria-label="twitter">
                <Icon
                  type={IconTypes.twitter}
                  rootStyle={styles.social}
                  iconStyle={classNames(styles.icon, {
                    [styles.iconDark]: isDarkTheme,
                  })}
                />
              </a>
            </div>
          )}
        </div>
      </div>
      <div className={classNames(styles.wrapper, styles.container)} style={{ maxWidth: width }}>
        {showSocialIcons && (
          <div className={styles.content}>
            <img
              src={getAssetURL(`image.Footer.downloadIcons${isDarkTheme ? "Dark" : ""}`)}
              className={styles.downloadIcons}
              onClick={onClickDownload}
              alt="download icons"
            />
          </div>
        )}
        <div
          className={classNames(styles.content, {
            [styles.contentDark]: isDarkTheme,
          })}
        >
          <div
            className={classNames(styles.title, {
              [styles.titleDark]: isDarkTheme,
            })}
          >
            Découvrez-nous
          </div>
          <ul
            className={classNames(styles.list, {
              [styles.listDark]: isDarkTheme,
            })}
          >
            <li>
              <a href={consts.externalLink.press + "/fr/"} aria-label="press fr">
                {i18n.press}
              </a>
            </li>
            <li>
              <a href={consts.externalLink.press + "/en/"} aria-label="press eng">
                {i18n.pressRelease}
              </a>
            </li>
            <li>
              <a href={consts.externalLink.advertising} target="_blank" className={styles.mailto} aria-label="advertising">
                Molotov Advertising
              </a>
            </li>
            <li>
              <a href={consts.externalLink.welcometothejungle} rel="noopener noreferrer" target="_blank" aria-label="join the team">
                {i18n.job_offers}
              </a>
            </li>
          </ul>
        </div>
        <div
          className={classNames(styles.content, {
            [styles.contentDark]: isDarkTheme,
          })}
        >
          <div
            className={classNames(styles.title, {
              [styles.titleDark]: isDarkTheme,
            })}
          >
            Besoin d'aide
          </div>
          <ul
            className={classNames(styles.list, {
              [styles.listDark]: isDarkTheme,
            })}
          >
            <li>
              <a href={consts.externalLink.support} target="_blank" aria-label="support">
                {i18n.helpOnline}
              </a>
            </li>
            <li>
              <Link to={consts.routes.privacy}>{i18n.privacyPolicy}</Link>
            </li>
            <li>
              <Link to={consts.routes.mentions}>{i18n.mentions}</Link>
            </li>
            <li>
              <Link to={consts.routes.cookies}>{i18n.cookies}</Link>
            </li>
            <li>
              <Link to={consts.routes.gtu}>{i18n.termsOfUse}</Link>
            </li>
            <li>
              <button
                type="button"
                aria-label="cookie preferences"
                onClick={() => {
                  openCookieModal()
                  window.Didomi.preferences.show()
                }}
              >
                {i18n.cookieSettings.editSettings}
              </button>
            </li>
            <li>
              <button type="button" arial-label="download app" onClick={onClickDownload}>
                {i18n.publicMenu.download}
              </button>
            </li>
            <li>
              <Link to={consts.routes.hardware}>{i18n.publicMenu.devices}</Link>
            </li>
          </ul>
        </div>
        <div
          className={classNames(styles.content, {
            [styles.contentDark]: isDarkTheme,
          })}
        >
          <div
            className={classNames(styles.title, {
              [styles.titleDark]: isDarkTheme,
            })}
          >
            Sur Molotov
          </div>
          <ul
            className={classNames(styles.list, {
              [styles.listDark]: isDarkTheme,
            })}
          >
            {!!channels.length &&
              consts.onMolotovChannels.map(channel => {
                const foundChannel = channels.find(chan => channel === get(chan, "data.slug_seo", ""))
                return (
                  <li key={foundChannel.getTitle()}>
                    <Link to={foundChannel.getUrl(locale)}>{foundChannel.getTitle()} direct</Link>
                  </li>
                )
              })}
          </ul>
        </div>
        <div
          className={classNames(styles.content, {
            [styles.contentDark]: isDarkTheme,
          })}
        >
          <div
            className={classNames(styles.title, {
              [styles.titleDark]: isDarkTheme,
            })}
          >
            Nos programmes
          </div>
          <ul
            className={classNames(styles.list, {
              [styles.listDark]: isDarkTheme,
            })}
          >
            <li>
              <Link to={formatterHelper.basic(consts.routes.seoHome, { locale })}>{i18n.publicMenu.seoHome}</Link>
            </li>
            <li>
              <Link
                to={formatterHelper.basic(consts.routes.seoHomeSection, {
                  locale,
                  section: consts.slugs.tonight_1,
                })}
              >
                {i18n.publicMenu.seoHomeTonight}
              </Link>
            </li>
            <li>
              <Link to={consts.routes.tvReplay}>{i18n.seoFooter.home.replay}</Link>
            </li>
            {!!categories.length &&
              consts.footerCategories.map(category => {
                const foundCategory = categories.find(cat => category === get(cat, "slug_seo", ""))
                return (
                  <li key={foundCategory.id}>
                    <Link
                      to={formatterHelper.basic(consts.routes.category, {
                        locale,
                        categoryId: foundCategory.id,
                        categorySlug: foundCategory.slug,
                      })}
                    >
                      {foundCategory.label} streaming
                    </Link>
                  </li>
                )
              })}
            <li>
              <a href={consts.externalLink.molotovTvAwards} aria-label="molotov tv awards">
                {i18n.publicMenu.molotovTvAwards}
              </a>
            </li>
            <li>
              <a href={consts.externalLink.emissionsTv} aria-label="emissions tv">
                {i18n.publicMenu.emissionsTv}
              </a>
            </li>
          </ul>
        </div>
        <div
          className={classNames(styles.content, {
            [styles.contentDark]: isDarkTheme,
          })}
        >
          <div
            className={classNames(styles.title, {
              [styles.titleDark]: isDarkTheme,
            })}
          >
            Nos offres
          </div>
          <ul
            className={classNames(styles.list, {
              [styles.listDark]: isDarkTheme,
            })}
          >
            <li>
              <a href={consts.externalLink.extra} aria-label="molotov extra">
                {i18n.publicMenu.molotovExtra}
              </a>
            </li>
            <li>
              <a href={consts.externalLink.extended} aria-label="molotov extended">
                {i18n.publicMenu.molotovExtended}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div
        className={classNames(styles.wrapper, styles.date, {
          [styles.dateDark]: isDarkTheme,
        })}
        style={{ maxWidth: width }}
      >
        ©Molotov {new Date().getFullYear()}
      </div>
    </footer>
  )
}

Footer.propTypes = {
  isDarkTheme: PropTypes.bool,
  width: PropTypes.number,
}
