import { push } from "connected-react-router"

import {
  GET_GIFT_CONGRATS_PENDING,
  GET_GIFT_CONGRATS_SUCCESS,
  GET_GIFT_CONGRATS_FAILED,
  CLEAR_GIFT_OPTIONS,
  GET_GIFT_BUY_PENDING,
  GET_GIFT_BUY_SUCCESS,
  GET_GIFT_BUY_FAILED,
  GET_GIFT_CLAIM_PENDING,
  GET_GIFT_CLAIM_SUCCESS,
  GET_GIFT_CLAIM_FAILED,
  GET_GIFT_OPTIONS_PENDING,
  GET_GIFT_OPTIONS_SUCCESS,
  GET_GIFT_OPTIONS_FAILED,
  SAVE_CLAIM_CODE,
  VALIDATE_GIFT_SUCCESS,
} from "consts/actions"

import { executeApiActions } from "actions/api"
import stateHelper from "helpers/state"
import formatterHelper from "helpers/formatter"

import consts from "consts"

export function fetchGiftOptions(action) {
  return {
    accessTokenRequired: false,
    request: {
      base: "",
      url: `${action.url}`,
      method: "GET",
    },
    onStart: (payload, meta, dispatch) => {
      dispatch({
        type: GET_GIFT_OPTIONS_PENDING,
      })
    },
    onSuccess: (payload, meta, dispatch) => {
      return dispatch({
        type: GET_GIFT_OPTIONS_SUCCESS,
        payload: { data: payload },
      })
    },
    onError: (payload, meta, dispatch) => {
      return dispatch({
        type: GET_GIFT_OPTIONS_FAILED,
        payload: stateHelper.errorPayload(payload.user_message, meta.httpCode),
      })
    },
  }
}

export function fetchGiftClaim() {
  return {
    accessTokenRequired: true,
    request: {
      url: `${consts.endpoints.giftCode}`,
      method: "GET",
    },
    onStart: (payload, meta, dispatch) => {
      dispatch({
        type: GET_GIFT_CLAIM_PENDING,
      })
    },
    onSuccess: (payload, meta, dispatch) => {
      return dispatch({
        type: GET_GIFT_CLAIM_SUCCESS,
        payload: { data: payload },
      })
    },
    onError: (payload, meta, dispatch) => {
      return dispatch({
        type: GET_GIFT_CLAIM_FAILED,
        payload: stateHelper.errorPayload(payload.user_message, meta.httpCode),
      })
    },
  }
}

export function fetchGiftBuy(rateplan) {
  return {
    accessTokenRequired: true,
    request: {
      url: formatterHelper.basic(consts.endpoints.giftProduct, { rateplan }),
      method: "GET",
    },
    onStart: (payload, meta, dispatch) => {
      dispatch({
        type: GET_GIFT_BUY_PENDING,
      })
    },
    onSuccess: (payload, meta, dispatch) => {
      if (payload.buy_action) {
        dispatch(executeApiActions(payload.buy_action))
      }
      return dispatch({
        type: GET_GIFT_BUY_SUCCESS,
        payload: { data: payload },
      })
    },
    onError: (payload, meta, dispatch) => {
      return dispatch({
        type: GET_GIFT_BUY_FAILED,
        payload: stateHelper.errorPayload(payload.user_message, meta.httpCode),
      })
    },
  }
}

export function fetchGiftSuccess(action) {
  return {
    accessTokenRequired: true,
    request: {
      base: "",
      url: `${action.url}`,
      method: "GET",
    },
    onStart: (payload, meta, dispatch) => {
      dispatch({
        type: GET_GIFT_CONGRATS_PENDING,
      })
    },
    onSuccess: (payload, meta, dispatch) => {
      dispatch(push(consts.routes.giftcongrats))

      return dispatch({
        type: GET_GIFT_CONGRATS_SUCCESS,
        payload: { data: payload },
      })
    },
    onError: (payload, meta, dispatch) => {
      return dispatch({
        type: GET_GIFT_CONGRATS_FAILED,
        payload: stateHelper.errorPayload(payload.user_message, meta.httpCode),
      })
    },
  }
}

export function fetchPromoCodeSuccess(action) {
  return {
    accessTokenRequired: true,
    request: {
      base: "",
      url: `${action.url}`,
      method: "POST",
      payload: action.payload,
    },
    onSuccess: (payload, meta, dispatch) => {
      if (payload.execute_actions) {
        dispatch(executeApiActions(payload.execute_actions))
      }
    },
    onError: (payload, meta, dispatch) => {
      return dispatch({
        type: GET_GIFT_CONGRATS_FAILED,
        payload: stateHelper.errorPayload(payload.user_message, meta.httpCode),
      })
    },
  }
}

export function clearGiftOptions() {
  return {
    type: CLEAR_GIFT_OPTIONS,
  }
}

export function validateGiftCode(payload) {
  return {
    type: VALIDATE_GIFT_SUCCESS,
    payload,
  }
}

export function saveClaimCode(payload) {
  return {
    type: SAVE_CLAIM_CODE,
    payload,
  }
}
