import Promise from "bluebird"
import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classNames from "classnames"
import { throttle } from "lodash"

import RouterSwitch from "components/RouterSwitch/view"
import Header from "containers/Header"
import Flash from "containers/Flash"
import Footer from "components/Footer"
import Feedbacks from "components/Feedbacks"
import Nav from "components/Nav"
import Page500 from "components/Page500"
import PageNotFound from "containers/PageErrors/PageNotFound"

import modalType from "containers/Modal/types"

import { open as openModal } from "actions/modal"
import { getReferences, getAppReferences } from "actions/references"
import { getSEOConfig } from "actions/seo"
import { getUserIsFrench } from "actions/openEurope"
import { allowClientRender, catchError } from "actions/ui"
import { hideUserMenu, scroll } from "actions/ui"

import selectAuthentication from "selectors/authentication"
import { selectQuery } from "selectors/location"

import routeHelper from "helpers/route"
import styles from "./index.css"
import consts from "consts"

@connect((state, ownProps) => ({
  appError: state.ui.appError,
  app404Error: state.ui.app404Error,
  authentication: selectAuthentication(state),
  backgroundImage: state.ui.backgroundImage,
  frameless: state.appSettings.frameless,
  frameType: state.appSettings.frameType,
  flashStatus: state.flash.status,
  location: ownProps.location,
  navVisible: state.ui.navVisible,
  query: selectQuery(ownProps),
  references: state.references,
  serverError: state.serverError,
  showMobileNav: state.ui.showMobileNav,
  openEurope: state.openEurope,
}))
export default class App extends Component {
  static propTypes = {
    appError: PropTypes.bool,
    app404Error: PropTypes.bool,
    backgroundImage: PropTypes.string,
    flashStatus: PropTypes.string,
    authentication: PropTypes.object,
    frameType: PropTypes.string,
    frameless: PropTypes.bool,
    location: PropTypes.object,
    navVisible: PropTypes.bool,
    flash: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    query: PropTypes.object,
    references: PropTypes.object,
    serverError: PropTypes.bool,
    showMobileNav: PropTypes.bool,
    serverCookies: PropTypes.string,
    openEurope: PropTypes.object,
  }

  static fetchData(dispatch, state) {
    if (state.references.pending) {
      return Promise.resolve()
    }

    return [dispatch(getReferences()), dispatch(getAppReferences()), dispatch(getSEOConfig())]
  }

  any(arr) {
    /*
            This is written in this form to ensure that predicates are isolated
            from one another. [].some is passed an "identity function" which simply
            returns what the value it's given as we have a list of booleans.
            Iteration will stop one the first expression that evaluates to true
        */
    return arr.some(b => b)
  }

  isFrameless = () => {
    return this.props.frameless || (this.props.location.search && routeHelper.isDesktopMode(this.props.location.search))
  }

  isWrapperFull(pathname, frameless) {
    return this.any([routeHelper.isSingleOfferPage(pathname) && frameless, routeHelper.isWrapperFullPage(pathname)])
  }

  isWrapperLarge(pathname) {
    // for travel page
    return (
      !this.props.app404Error &&
      this.any([
        routeHelper.isGiftPage(pathname),
        routeHelper.isTravelPage(pathname),
        routeHelper.isStrapiLandingPage(pathname),
        routeHelper.isWinbackPage(pathname),
      ])
    )
  }

  isWrapperFullWidth(pathname) {
    return this.any([routeHelper.isHardwarePage(pathname)])
  }

  isWrapperNav(pathname) {
    return this.any([routeHelper.isNavPage(pathname)])
  }

  isWrapperForCenteredContent(pathname) {
    return (
      (routeHelper.isAccountPage(pathname) && !this.props.authentication.isAuthenticated) ||
      routeHelper.isCampaignPage(pathname) ||
      (routeHelper.isGiftPage(pathname) && !this.isFrameless()) ||
      (routeHelper.isWinbackPage(pathname) && !this.isFrameless())
    )
  }

  isDevicePage(pathname) {
    return this.any([routeHelper.isDevicePage(pathname)])
  }

  isDeeplinkPage() {
    return this.any([routeHelper.isDeeplinkPage(this.props.location.pathname)])
  }

  isOnMyTvPage(pathname) {
    return this.any([routeHelper.isOnMyTvPage(pathname)])
  }

  onAppClick = () => {
    this.props.dispatch(hideUserMenu())
  }

  watchScroll = () => {
    this.props.dispatch(scroll(window.scrollY))
  }

  addScrollListener() {
    if (!this.scrollRegistered && __IS_CLIENT__) {
      this.scrollRegistered = true
      window.addEventListener("scroll", this.watchScroll)
    }
  }

  renderRoutes() {
    return <RouterSwitch groupName="appRoutes" serverCookies={this.props.serverCookies} />
  }

  isFromSubscribeChoseOption() {
    return routeHelper.isSubscribeChoseOption(this.props.location.pathname) || this.props.location.search.includes("from=options")
  }

  hasOpenEuropeCookie = () => document.cookie.indexOf("openEuropePopinSeen") > -1

  handleOpenEurope = () => {
    if (!this.isFrameless() && !this.hasOpenEuropeCookie() && !this.props.openEurope.is_france && !this.props.openEurope.pending) {
      this.props.dispatch(openModal(modalType.openEurope, "", () => {}, false))
    }
  }

  constructor(props) {
    super(props)

    this.watchScroll = throttle(this.watchScroll, 100)
  }

  componentDidMount() {
    if (!global.serverRendered) {
      this.constructor.fetchData(this.props.dispatch, this.props)
    }

    if (this.props.location.pathname === consts.routes.default || this.props.location.pathname === consts.routes.travel) {
      this.addScrollListener()
    }

    if (!this.hasOpenEuropeCookie()) {
      this.props.dispatch(getUserIsFrench())
    }

    this.props.dispatch(allowClientRender())
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname === consts.routes.default && this.props.location.pathname !== prevProps.location.pathname) {
      this.addScrollListener()
    }

    if (!prevProps.authentication.isAuthenticated && this.props.authentication.isAuthenticated) {
      this.constructor.fetchData(this.props.dispatch, this.props)
    }

    if (prevProps.openEurope.is_france !== this.props.openEurope.is_france) {
      this.handleOpenEurope()
    }
  }

  componentDidCatch() {
    this.props.dispatch(catchError())
  }

  render() {
    const {
      appError,
      app404Error,
      location: { pathname },
      location,
      navVisible,
      frameType,
      flashStatus,
      serverError,
      showMobileNav,
    } = this.props

    let isHome = false

    if (pathname === consts.routes.default) {
      isHome = true
    }

    const isCreateAccountDSP = pathname.indexOf(consts.routes.createAccount.replace(":token", "")) > -1

    let dynamicStyles = null
    if (!this.isFrameless() && (routeHelper.isGiftPage(pathname) || routeHelper.isPromoCodePage(pathname))) {
      dynamicStyles = {
        backgroundImage: `url(${this.props.backgroundImage})`,
      }
    }

    const displayError = serverError || appError || app404Error

    return (
      <div onClick={this.onAppClick} className={styles.root}>
        {!this.isFrameless() && !app404Error && !this.isFromSubscribeChoseOption() && <Header pathname={pathname} />}

        <Flash
          status={flashStatus}
          customStyle={classNames(styles.flash, {
            [styles.webViewFlash]: this.isFrameless(),
          })}
        />

        <Feedbacks
          rootClass={classNames({
            [styles.framelessFeedbacks]: this.isFrameless(),
            [styles.winbackFeedbacks]: routeHelper.isWinbackPage(pathname),
          })}
        />

        <div
          className={classNames(styles.wrapper, {
            [styles.wrapperHome]: isHome,
            [styles.wrapperForCenteredContent]: this.isWrapperForCenteredContent(pathname),
            [styles.wrapperFrameless]: this.isFrameless(),
            [styles.wrapperMobile]: frameType === consts.frameType.mobile,
            [styles.wrapperFull]: this.isWrapperFull(pathname, this.isFrameless()),
            [styles.wrapperLarge]: this.isWrapperLarge(pathname),
            [styles.wrapperFullWidth]: this.isWrapperFullWidth(pathname, this.isFrameless()),
            [styles.wrapperWinbackPage]: routeHelper.isWinbackPage(pathname),
            [styles.wrapperWithNav]: navVisible,
            [styles.wrapperNav]: this.isWrapperNav(pathname),
            [styles.wrapperDarkBackground]: frameType === consts.frameType.tv,
            [styles.wrapperDevicePage]: this.isDevicePage(pathname),
            [styles.wrapperOnMyTvPage]: this.isOnMyTvPage(pathname),
            [styles.wrapperBackgroundDSP]: isCreateAccountDSP,
          })}
          style={dynamicStyles}
        >
          {displayError ? app404Error ? <PageNotFound /> : <Page500 /> : showMobileNav ? <Nav location={location} /> : this.renderRoutes()}
        </div>
        {!this.isFrameless() && !showMobileNav && !this.isDeeplinkPage() && !app404Error && <Footer pathname={pathname} width={1120} />}
      </div>
    )
  }
}
