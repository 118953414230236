import { createSelector } from "reselect"
import { values } from "lodash"

import ProgramModel from "models/Program"
import EpisodeModel from "models/Episode"
import PersonModel from "models/Person"

import { selectChannels } from "selectors/references"
import { selectSections } from "selectors/section"

import consts from "consts"

const selectPrograms = state => state.programs

const _selectSEOHomePrograms = createSelector([selectPrograms], programs => programs.seoHome)

export const selectSEOHomePrograms = createSelector([_selectSEOHomePrograms, selectChannels], (programs, channels) => {
  if (programs.error) {
    return {
      error: programs.error,
    }
  }

  if (programs.pending || !programs.sections || !channels.length) {
    return {
      pending: true,
      getSections() {
        return []
      },
    }
  }

  const sections = selectSections(values(programs.sections), { channels })

  return {
    ...programs,
    getSections() {
      return sections
    },
  }
})

const selectEpisodes = state => state.episodes

const selectProgramCasting = casting => {
  return casting && casting.items
    ? {
        ...casting,
        items: casting.items.map(person => new PersonModel(person)),
      }
    : false
}

const getIds = ({ location, query, params }) => {
  let programId
  let channelId
  let isEpisode = false

  if (location.pathname === "/deeplink") {
    channelId = query.channel_id
    programId = query.id

    if (query.type === consts.deepLinkTypes.EPISODE) {
      programId = query.program_id
      isEpisode = true
    }
  } else {
    channelId = params.channelId
    programId = params.programId
  }

  return {
    channelId,
    programId,
    isEpisode,
  }
}

export const selectProgram = (state, parameters) => {
  const { channelId, programId, isEpisode } = getIds(parameters)

  return createSelector([selectPrograms, selectEpisodes, selectChannels], (programs, episodes, channels) => {
    const programData = isEpisode ? episodes : programs.programs[`${programId}-${channelId}`]

    if (!programData || programData.pending || !channels.length) {
      return {
        pending: true,
      }
    }

    if (programData.error) {
      return {
        error: programData.error,
      }
    }

    const isValid = isEpisode ? programData.episode && programData.episode.id : programData.program && programData.program.id

    if (!isValid) {
      return {
        error: {
          code: 410,
        },
      }
    }

    const channel = channels.find(c => c.getId() === channelId)
    const casting = selectProgramCasting(programData.casting)
    const channelEpisodeSections = selectSections(programData.channel_episode_sections, { channels })
    const episodeSections = selectSections(programData.program_episode_sections, { channels, itemEntity: "episode", extra: { programId } })
    const sharing = programData.sharing
    const programMetadata = programData.metadata

    const programModel = isEpisode
      ? new EpisodeModel(
          {
            ...programData.episode,
            channel,
            channelEpisodeSections,
            sharing,
            ...(programData.offer && { offer: programData.offer }),
          },
          programId,
          episodeSections,
          casting
        )
      : new ProgramModel(
          {
            ...programData.program,
            channel,
            channelEpisodeSections,
            sharing,
            ...(programData.offer && { offer: programData.offer }),
          },
          episodeSections,
          casting,
          programMetadata
        )

    return programModel
  })(state)
}

export const selectMetadata = (state, parameters) => {
  const { channelId, programId, isEpisode } = getIds(parameters)

  return createSelector([selectPrograms, selectEpisodes], (programs, episodes) => {
    const programData = isEpisode ? episodes : programs.programs[`${programId}-${channelId}`]

    return programData && programData.metadata ? programData.metadata : consts.emptyObject
  })(state)
}
