import { SET_LOCALE } from "consts/actions"
import { selectLocale } from "selectors/local"

export function setLocale(locale) {
  return (dispatch, getState) => {
    const currentLocale = selectLocale(getState())

    if (currentLocale !== locale) {
      dispatch({
        type: SET_LOCALE,
        locale,
      })
    }
  }
}
