import React from "react"
import { hydrate } from "react-dom"
import { Route, matchPath } from "react-router-dom"
import { Provider } from "react-redux"
import { ConnectedRouter } from "connected-react-router"
import { createBrowserHistory } from "history"
import Promise from "bluebird"

// Components
import AppWrapper from "components/AppWrapper/view"

// Action
import { checkAutologinViaRefreshToken, checkAutologinViaAccessToken, checkLogin } from "actions/session"
import { setNavigator } from "actions/navigator"
import { setWindowHeight, setWindowWidth, scroll } from "actions/ui"
import { applyPrivacySettings, displayCookieBanner, setPrivacySettings } from "actions/privacy"

// Managers & helpers
import historyManager from "managers/historyManager"
import routeHelpers from "helpers/route"
import localStorageHelper from "helpers/localStorage"
import urlHelper from "helpers/url"
import cookieHelper from "helpers/cookies"

// Selectors
import selectNavigator from "selectors/navigator"

// consts
import getMiddlewares from "./middlewares/getMiddlewares"
import createStore from "./store"
import * as routerConfig from "consts/router"
import consts from "consts"
consts.config = window.mltvConf

// Global CSS
import "css/reset.css"
import "css/layout.css"

const query = routeHelpers.queryToObject(location.search)

let molotovAgent

try {
  molotovAgent = JSON.parse(query["molotov-agent"])
} catch (e) {}

const history = createBrowserHistory()

const store = createStore(
  history,
  getMiddlewares(consts, history, {
    molotovAgent,
    navigator: selectNavigator({ navigator: window.navigator }),
  })
)

store.dispatch(setNavigator(window.navigator))

// If the Landing is displayed in frameless mode, this means that it's embedded in apps via a webview / iframe.
// We always want to have tracking if the landing is embedded in apps.
if (!!query.frameless) {
  store.dispatch(applyPrivacySettings())
} else {
  // cookies and tracking are enabled by default
  // unless the user explicitly choose to change that
  if (localStorageHelper.getCachedData(consts.localStorageKey.cookieSettings)) {
    try {
      const privacySettings = JSON.parse(localStorageHelper.getCachedData(consts.localStorageKey.cookieSettings))

      if (privacySettings.expire > +new Date()) {
        store.dispatch(setPrivacySettings(privacySettings))
      } else {
        store.dispatch(displayCookieBanner())
      }
      store.dispatch(applyPrivacySettings())
    } catch (e) {}
  } else {
    store.dispatch(displayCookieBanner())
  }
}

if (window.location.search.includes("irclickid")) {
  const clickId = window.location.search.split("irclickid=")[1].split("&")[0]

  if (store.getState().privacy.settingsApplied) {
    cookieHelper.setCookie({
      name: consts.cookieNames.clickId,
      value: clickId,
      expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 30).toGMTString(),
      domain: urlHelper.getCurrentDomainWithExtension(),
      sameSite: "Lax",
    })
  } else {
    window.clickId = clickId
  }
}

history.listen(location => {
  historyManager.setHistory(location.pathname)
})

/*
    To avoid having a loader and a double data fetch
    on routes where a component is lazy-loaded, we need
    to preload all components that will be used on the
    first rendered route.
*/
const flattenedRoutes = routeHelpers.flattenRouterConfig(routerConfig)
const matchedRouteLoadablePromises = []

flattenedRoutes
  .filter(route => route.path)
  .forEach(route => {
    const match = matchPath(location.pathname, route)

    if (match && match.isExact && route.loadable) {
      matchedRouteLoadablePromises.push(route.loadable.preload())
    }
  })

// wait for all modules to be loaded before hydrating
Promise.all(matchedRouteLoadablePromises).then(() => {
  // Do not run the client app if we are on google cache website
  // or it will display a 404. It may or may not improve our SEO
  if (/webcache/.test(location.hostname)) {
    return
  }

  hydrate(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Route component={AppWrapper} />
      </ConnectedRouter>
    </Provider>,
    document.getElementById("app"),
    () => {
      window.serverRendered = false
      // Check autologin (from refreshToken=XXX in URL)
      if (window.location.search && window.location.search.match(/\brefreshToken\b/)) {
        store.dispatch(checkAutologinViaRefreshToken())
      } else if (window.location.search && window.location.search.match(/\btoken\b/)) {
        store.dispatch(checkAutologinViaAccessToken())
      }
      // Check login (from localStorage) if not in frameless mode
      else if (!/frameless/.test(window.location.href)) {
        store.dispatch(checkLogin())
      }
      store.dispatch(scroll(window.scrollY))
      store.dispatch(setWindowHeight(window.innerHeight))
      store.dispatch(setWindowWidth(window.innerWidth))
    }
  )
})
