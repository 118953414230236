import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classNames from "classnames"
import { Link } from "react-router-dom"
import get from "lodash/get"

/* Components */
import ImageSlider from "components/ImageSlider"
import Input from "components/Input"
import InputSubmit from "components/InputSubmit"
import BirthdayForm from "components/BirthdayForm"
import GenderForm from "components/GenderForm"

/* Actions */
import { createDSPAccount } from "actions/account"

/* Selectors */
import { selectQuery } from "selectors/location"
import { selectErrorMessages } from "selectors/errorMessages"

/* Helpers */
import { getAssetURL } from "helpers/static"
import routeHelpers from "helpers/route"

/* consts */
import consts from "consts"
import i18n from "consts/i18n"
import routes from "consts/routes"

/* Styles */
import styles from "./index.css"

@connect((state, ownProps) => ({
  query: selectQuery(ownProps),
  errorMessages: selectErrorMessages(state),
  passwordForm: state.forms.password,
  account: state.account,
}))
export default class PageCreateAccountFromDSP extends Component {
  static propTypes = {
    query: PropTypes.object,
    errorMessages: PropTypes.object,
    dispatch: PropTypes.func,
    match: PropTypes.object,
    passwordForm: PropTypes.object.isRequired,
    account: PropTypes.object,
  }

  state = {
    errors: {},
    password: {
      value: "",
    },
    confirmation: {
      value: "",
    },
    day: "",
    month: "",
    year: "",
    gender: "",
  }

  resetError(state, fieldName, value) {
    state[fieldName] = { error: null, value }
  }

  setError(state, fieldName, value, error) {
    state[fieldName] = { ...this.state[fieldName], error, valid: false, value }
  }

  setValid(state, fieldName, value) {
    state[fieldName] = { ...this.state[fieldName], error: null, valid: true, value }
  }

  updateState = (passwordValue = null, confirmationValue = null) => {
    const newState = {}
    const password = passwordValue !== null && passwordValue.length ? passwordValue : this.state.password.value
    const confirmation = confirmationValue !== null && confirmationValue.length ? confirmationValue : this.state.confirmation.value

    if (password.length) {
      if (password.length < consts.minPasswordLength) {
        this.setError(newState, "password", password, i18n.changePasswordPage.minimumCharsCount)
        this.resetError(newState, "confirmation", confirmation)
      } else {
        this.setValid(newState, "password", password)
      }
    } else {
      this.resetError(newState, "password", password)
      this.resetError(newState, "confirmation", confirmation)
    }

    if (newState.password.valid === true && confirmation.length) {
      if (confirmation !== password) {
        this.setError(newState, "confirmation", confirmation, i18n.changePasswordPage.nonIdenticalPasswords)
      } else if (confirmation.length >= consts.minPasswordLength) {
        this.setValid(newState, "password", password)
        this.setValid(newState, "confirmation", confirmation)
      }
    } else {
      this.resetError(newState, "confirmation", confirmation)
    }

    if (Object.keys(newState).length) {
      this.setState(newState)
    }
  }

  handleRegistrationSuccess = () => {
    window.location = routeHelpers.getDownloadURL("marketing_site")
  }

  submit = event => {
    event.preventDefault()

    const { password, confirmation, day, month, year, gender } = this.state
    const { token } = this.props.match.params

    const errors = {
      birthday: day.length === 0 || month.length === 0 || year.length === 0,
      day: day.length === 0 || day === "Jour",
      month: month.length === 0 || month === "Mois",
      year: year.length === 0 || year === "Année",
      gender: gender.length === 0,
    }

    this.setState({ errors })

    const isPasswordOK = password.value === confirmation.value && password.valid && confirmation.valid

    if (Object.keys(errors).filter(e => errors[e]).length || !isPasswordOK) {
      return
    }

    const birthday = `${year}-${month}-${day}`

    this.props.dispatch(createDSPAccount(token, password.value, birthday, gender, null, this.handleRegistrationSuccess))
  }

  onInputChange = (name, value) => {
    this.setState({
      errors: {
        ...this.state.errors,
        [name]: false,
        birthday: ["day", "month", "year"].indexOf(name) !== -1 ? false : this.state.errors.birthday,
      },
      [name]: value,
    })
  }

  onGenderChange = value => {
    this.setState({
      errors: {
        ...this.state.errors,
        gender: false,
      },
      gender: value,
    })
  }

  render() {
    const {
      query: { dsp },
      errorMessages,
    } = this.props
    const { password, confirmation, errors } = this.state

    const dspLogo = getAssetURL(`image.dsp.${dsp}.logo`)
    const dspLogox2 = getAssetURL(`image.dsp.${dsp}.logox2`)

    return (
      <div className={styles.createAccountWrapper}>
        <div className={styles.leftWrapper}>
          <div>
            <img loading="lazy" src={dspLogo} srcSet={`${dspLogo} 1x, ${dspLogox2} 2x`} />
          </div>
          <h1>Finaliser mon inscription</h1>

          <form onSubmit={this.submit}>
            <label className={styles.label}>{i18n.createAccountPage.createPassword}</label>
            <Input
              type="password"
              // placeholder={i18n.changePasswordPage.newPassword}
              success={password.valid}
              rootStyle={styles.inputRoot}
              customStyle={styles.input}
              error={password.valid === false}
              onChange={value => {
                this.updateState(value, null)
              }}
            />

            <span className={styles.inputErrorMessage}>{this.state.password.error}</span>
            <label className={styles.label}>{i18n.createAccountPage.confirmPassword}</label>
            <Input
              type="password"
              disabled={!password.valid}
              // placeholder={i18n.changePasswordPage.confirmPassword}
              success={confirmation.valid}
              error={confirmation.valid === false}
              rootStyle={styles.inputRoot}
              customStyle={styles.input}
              onChange={value => {
                this.updateState(null, value)
              }}
            />
            <span className={styles.inputErrorMessage}>{this.state.confirmation.error}</span>

            <BirthdayForm
              errors={errors}
              errorMessages={errorMessages}
              onInputChange={this.onInputChange}
              customStyle={styles.birthDayCustomStyle}
              errorStyle={styles.errorStyle}
            />

            <GenderForm
              error={errors.gender}
              errorMessages={errorMessages}
              onChange={this.onGenderChange}
              gender={this.state.gender}
              errorStyle={styles.errorStyle}
            />

            <p className={styles.legals}>
              En continuant vous acceptez les <Link to={routes.gtu}>Conditions d'utilisation</Link>, la{" "}
              <Link to={routes.privacy}>Politique de confidentialité</Link> et <Link to={routes.cookies}>l’Utilisation des cookies</Link> de Molotov.
            </p>

            <InputSubmit type={InputSubmit.types.yellowFull} customStyle={classNames(styles.button)} onClick={this.submit} value={i18n.terminate} />

            {get(this.props.account, "error.message") && <p className={styles.errorStyle}>{get(this.props.account, "error.message")}</p>}
          </form>
        </div>
        <div className={styles.rightWrapper}>
          <ImageSlider width={380} height={325} dsp={dsp} autoplay={true} />
        </div>
      </div>
    )
  }
}
